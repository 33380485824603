.ms-auto {
    font-family: "Aclonica";
}

.navbar-collapse {
    background-color: black;
    width: 100%;
}

.navbar-wrapper {
    width: 100%;
    font-size: xx-large;
    height: 15px;
}

.navbar-brand {
    padding-top: 0px !important;
}

.nav-link {
    /* color: rgb(177, 124, 27) !important; */
    /* color: orangered !important; */
    color: #046307 !important;
    text-shadow: 1px 1px whitesmoke;

}

.logo {
    margin-top: 0px;
    margin-left: 0px;
    height: 95px;
    z-index: 100;
    display: block;
    /* transform: rotate(-5deg); */
}

.mobilelogo {
    margin-top: 0px;
    margin-left: 0px;
    height: 95px;
    z-index: 100;
    display: none;
    /* transform: rotate(-5deg); */
}

@media screen and (max-width: 900px) {
    .welcomeText {
        font-size: 1.2rem !important;
    }

    .columnSetting {
        padding-left: 0px;
    }
    
    .menuHeader {
        font-size: 1.2rem !important;
    }

    .menuDescrip {
        font-size: 0.9rem !important;
        margin-bottom: 15px;
    }

    .price {
        font-size: 1.0rem !important;
        text-align: right;
        padding-right: 0px;
    }
    
    .desktopVisible {
        display: none
    }

    .front_picture {
        margin-top: 40px !important;
        height: 200px !important;
    }

    .picture {
        background-position-y: 0px !important;
        height: 190px !important;
    }

    .btnspecial {
        margin-bottom: 40px;
    }

    .formText {
        text-align: left;
    }

    .infoFooter {
        margin-bottom: 170px;
        font-size: 1.4rem !important;
    }

    .header1 {
        margin-top: 20px !important;
    }

    .mobilelogo {
        height: 45px !important;
        display: block;
    }

    .logo {
        display: none;
    }

    .navbar {
        padding-top: 0.3rem !important;
    }
}