.infoFooter {
    /* text-align: left;
    margin-left: 15px; */
    font-size: 25px;
    font-family: "Cormorant upright";
    text-shadow: 1px 1px black;
    color: white;
    background: none
}

.footerLink a:link {
    color: #fff !important;
}

a:link {
    color: #fff;
}

.FB {
    height: 30px;
}