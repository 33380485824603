.condiciones {
    font-size: 15px;
    margin-top: 15px;
}

.btnspecial {
color: white !important;
background-color: #e26826 !important;
margin-top: 15px !important;
font-family: "Cormorant upright";
font-size: 20px;
text-shadow: 1px 1px black;
border-color: white;

}

.form-control {
    background-color: #e26826 !important;
    color: white;  
    color: black !important;
  

}

.checkoutForm {
   margin-right: 15px;
}

.formText {
    font-size: 25px;
    font-family: "Cormorant upright";
    text-shadow: 1px 1px black;
    color: white;
}