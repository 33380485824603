.map-h2 {
    text-transform: uppercase;
    padding: 0px;
    padding-left: 5px;
    text-align: center;
    color: #000 !important;
    font-size: 0.6rem;
  }

  .divMap {
    width: 100%;
    height: 270px;
    border: 1px solid whitesmoke;
    border-radius: 5px;;
  }
  
  .google-map {
    height: 100%;
    width: 100%;
  }
  
  .pin {
    display: flex;
    align-items: center;
    width: 5vw;
    color: #000000;
  }
  
  .pin-icon {
    font-size: 2vw;
    font-size: 1rem;
    font-weight: bold;
  }
  
  .pin-text {
    font-size: 0.8rem;
    font-weight: bold;
  }
  
  @media screen and (min-width: 799px) {
    .google-map {
      height: 100%;
      width: 100%;
    }
  
    .map-h2 {
        font-size: 0.8rem;
        font-weight: 400;
    }
  
    .pin {
      width: 9vw;
    }
  
    .pin-icon {
      font-size: 3vw;
    }
  }