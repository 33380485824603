.linearGradient {
    /* background: linear-gradient( black, rgb(2, 39, 21), rgb(4, 43, 4), rgb(26, 24, 24), #582306, #ce5616); */
    /* background: linear-gradient( rgb(1, 24, 9), rgb(3, 41, 15), rgb(3, 54, 7), rgb(4, 54, 4), rgb(6, 66, 6)); */
    background: linear-gradient( rgb(1, 24, 9), rgb(3, 41, 15), rgb(3, 54, 7),  rgb(1, 24, 9), rgb(3, 41, 15));


    }

.front_picture {
    background-image: url(../pictures/front_new.jpg);
    height: 500px; 
    width: 100%;
    background-repeat: no-repeat;
    /* display: flex; */
    font-size: 50px;
    background-size: cover;
    background-position-y: -0px;
    margin-top: 100px;
    /* border-top: #046307 3px solid;
    border-bottom: #046307 3px solid; */
}

.header {
    /* color: orangered; */
    /* color: #046307; */
    color: #e26826;
    /* text-shadow: 1px 1px rgb(136, 127, 127); */
    text-shadow: 1px 1px whitesmoke;
    font-family: "Aclonica";
    padding-top: 20px;
}

.header1 {
    margin-top: 50px;
    /* color: orangered; */
    /* color: #046307; */
    color: #e26826;
    /* text-shadow: 1px 1px rgb(136, 127, 127); */
    text-shadow: 1px 1px whitesmoke;
    font-family: "Aclonica";
}

.header2 {
    margin-top: 30px;
    /* color: orangered; */
    /* color: #046307; */
    color: #e26826;
    /* text-shadow: 1px 1px rgb(136, 127, 127); */
    text-shadow: 1px 1px whitesmoke;
    text-align: left;
    margin-bottom: 25px;
    margin-left: 30px;
    font-family: "Aclonica";
}

.header3 {
    margin-top: 30px;
    /* color: orangered; */
    /* color: #046307; */
    color: #e26826;
    /* text-shadow: 1px 1px rgb(136, 127, 127); */
    text-shadow: 1px 1px whitesmoke;
    text-align: left;
    margin-bottom: 25px;
    font-family: "Aclonica";
}


.welcomeText {
    /* color: rgb(136, 127, 127); */
    font-size: 1.6rem;
    color: white;
    margin-left: 25px;
    margin-right: 25px;
    font-family: "Cormorant upright";
    font-weight: 300px;
}

.welcomeBorder {
    /* border-bottom: #046307 3px solid; */

}

.menu {
    text-align: left;
    margin-left: 15px;
    color: white;
}

.menuHeader {
    font-family: "Cormorant upright";
    font-size: 30px;
}

.menuDescrip{
    font-family: "Cormorant upright";
    font-size: 23px;

}

.underText {
    font-family: "Cormorant upright";
    font-size: medium;
    align-items: center;
    margin-top: 25px;
    color: white;

}

.underText1 {
    font-family: "Cormorant upright";
    font-size: large;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 50px;
    color: white;
}

.price {
    font-size: 1.8rem;
    text-align: right;
    font-family: "Cormorant upright";
}

.picture {
    background-image: url(../pictures/beer.jpg);
    height: 250px; 
    width: 100%;
    /* background-repeat: no-repeat; */
    /* display: flex; */
    background-size: cover;
    margin-top: 25px;
    margin-bottom: 25px;
    background-position-y: -210px;
    /* border-top: #046307 3px solid;
    border-bottom: #046307 3px solid; */
}

.divGallery {   
    justify-content: center;  
}

.row {
    margin-left: 0cm;
    margin-right: 0cm;
}

.galleryImg {
    height: 150px;
    width: auto;
    margin-right: 15px;
    margin-bottom: 15px;
    border-radius: 15px;
    box-shadow: whitesmoke 1px 1px 1px;   
    /* border-spacing: 0px !important;
    transform-box: fill-box !important; */
    padding: 0px;
    
    
}





