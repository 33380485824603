.App {
  text-align: center;
  /* background-image: linear-gradient( black, rgb(2, 39, 21), rgb(4, 43, 4), rgb(26, 24, 24), #582306, #e26826); */
  border-left-style: double;
  border-right-style: double;
  border-width: 10px;
  padding: 0px;

}  

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: black;
 
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* border-left: #046302 solid 5px;
  border-right: #046302 solid 5px; */
  /* border-left: #e26826 solid 8px;
  border-right: #e26826 solid 8px; */
  /* border-bottom: #046307 3px solid; */  
  
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
